import revive_payload_client_KIIWtdKFu8 from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dU1cbARsVM from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Dcrr5pmk6l from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_lKY8op8rIU from "/src/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.21.3_vite@5.4.10_@types+node@22.7.7_sass-embe_oqvyfrde65armhrvxxup5masy4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_hxJZMFap5j from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ThRVuv6k3r from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CR1JOu5MmH from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nLVNROxzxU from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_6fuhnImRWW from "/src/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_zbacBAXhj0 from "/src/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_l4wbEbsUbN from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/src/.nuxt/pwa-icons-plugin.ts";
import pwa_client_7hGhEwabay from "/src/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.21.3_vite@5.4._xgbxiyjfc5c4beiplh5htmcc6q/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_CisL1tUEHJ from "/src/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.10_@types+node@22.7.7_sass-embedded@1._g2byhyt277lbilohbfedcsjxom/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_ygRWOVBUHF from "/src/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.7_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import titles_dNCtX4UpR1 from "/src/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.13.0_magicast@0.3.5_rollup@4.21.3_vite@5.4.10_@types+node@22.7.7_e7xmajfvjga7o5ls3jueo2ppfq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_6DaK7wZhJ1 from "/src/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.13.0_magicast@0.3.5_rollup@4.21.3_vite@5.4.10_@types+node@22.7.7_e7xmajfvjga7o5ls3jueo2ppfq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_yQOmcakvxk from "/src/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.10_@types+node@22.7.7_sass-e_tln7fzo66h7a3loghyd2vjk3tu/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_pomHOT662i from "/src/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.10_@types+node@22.7.7_sass-e_tln7fzo66h7a3loghyd2vjk3tu/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_8tHeGvbJ14 from "/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_hXbU2HGAqR from "/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/src/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import vue_awesome_paginate_rvI9ZlY63V from "/src/plugins/vue-awesome-paginate.ts";
export default [
  revive_payload_client_KIIWtdKFu8,
  unhead_dU1cbARsVM,
  router_Dcrr5pmk6l,
  _0_siteConfig_lKY8op8rIU,
  payload_client_hxJZMFap5j,
  navigation_repaint_client_ThRVuv6k3r,
  check_outdated_build_client_CR1JOu5MmH,
  chunk_reload_client_nLVNROxzxU,
  plugin_vue3_6fuhnImRWW,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_l4wbEbsUbN,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_7hGhEwabay,
  plugin_CisL1tUEHJ,
  plugin_client_ygRWOVBUHF,
  titles_dNCtX4UpR1,
  defaultsWaitI18n_6DaK7wZhJ1,
  siteConfig_yQOmcakvxk,
  inferSeoMetaPlugin_pomHOT662i,
  switch_locale_path_ssr_8tHeGvbJ14,
  i18n_hXbU2HGAqR,
  plugin_wy0B721ODc,
  vue_awesome_paginate_rvI9ZlY63V
]
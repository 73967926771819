import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoArrowUpFromDot, LazySvgoBanknote, LazySvgoBookOpen, LazySvgoBriefcase, LazySvgoChevronDownCircle, LazySvgoChevronDown, LazySvgoChevronLeft, LazySvgoChevronRight, LazySvgoChevronUpCircle, LazySvgoCloseBig, LazySvgoCloseSmall, LazySvgoClose, LazySvgoCopy, LazySvgoEyeOff, LazySvgoEye, LazySvgoFacebook, LazySvgoFilter, LazySvgoFolderPlus, LazySvgoHammer, LazySvgoHeartPlus, LazySvgoHeart, LazySvgoHome, LazySvgoImage, LazySvgoInstagram, LazySvgoLogOut, LazySvgoMail, LazySvgoMapPinOff, LazySvgoMapPin, LazySvgoMaximize2, LazySvgoMenu, LazySvgoMessageCircle, LazySvgoMinus, LazySvgoMove3d, LazySvgoMoveDownRight, LazySvgoPause, LazySvgoPencilLine, LazySvgoPencilRuler, LazySvgoPhone, LazySvgoPinterest, LazySvgoPlay, LazySvgoPlus, LazySvgoRelax, LazySvgoSearch, LazySvgoShare2, LazySvgoStar, LazySvgoTrash, LazySvgoUserCircle2, LazySvgoUserCog2, LazySvgoXCircle, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoArrowUpFromDot", LazySvgoArrowUpFromDot],
["SvgoBanknote", LazySvgoBanknote],
["SvgoBookOpen", LazySvgoBookOpen],
["SvgoBriefcase", LazySvgoBriefcase],
["SvgoChevronDownCircle", LazySvgoChevronDownCircle],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoChevronLeft", LazySvgoChevronLeft],
["SvgoChevronRight", LazySvgoChevronRight],
["SvgoChevronUpCircle", LazySvgoChevronUpCircle],
["SvgoCloseBig", LazySvgoCloseBig],
["SvgoCloseSmall", LazySvgoCloseSmall],
["SvgoClose", LazySvgoClose],
["SvgoCopy", LazySvgoCopy],
["SvgoEyeOff", LazySvgoEyeOff],
["SvgoEye", LazySvgoEye],
["SvgoFacebook", LazySvgoFacebook],
["SvgoFilter", LazySvgoFilter],
["SvgoFolderPlus", LazySvgoFolderPlus],
["SvgoHammer", LazySvgoHammer],
["SvgoHeartPlus", LazySvgoHeartPlus],
["SvgoHeart", LazySvgoHeart],
["SvgoHome", LazySvgoHome],
["SvgoImage", LazySvgoImage],
["SvgoInstagram", LazySvgoInstagram],
["SvgoLogOut", LazySvgoLogOut],
["SvgoMail", LazySvgoMail],
["SvgoMapPinOff", LazySvgoMapPinOff],
["SvgoMapPin", LazySvgoMapPin],
["SvgoMaximize2", LazySvgoMaximize2],
["SvgoMenu", LazySvgoMenu],
["SvgoMessageCircle", LazySvgoMessageCircle],
["SvgoMinus", LazySvgoMinus],
["SvgoMove3d", LazySvgoMove3d],
["SvgoMoveDownRight", LazySvgoMoveDownRight],
["SvgoPause", LazySvgoPause],
["SvgoPencilLine", LazySvgoPencilLine],
["SvgoPencilRuler", LazySvgoPencilRuler],
["SvgoPhone", LazySvgoPhone],
["SvgoPinterest", LazySvgoPinterest],
["SvgoPlay", LazySvgoPlay],
["SvgoPlus", LazySvgoPlus],
["SvgoRelax", LazySvgoRelax],
["SvgoSearch", LazySvgoSearch],
["SvgoShare2", LazySvgoShare2],
["SvgoStar", LazySvgoStar],
["SvgoTrash", LazySvgoTrash],
["SvgoUserCircle2", LazySvgoUserCircle2],
["SvgoUserCog2", LazySvgoUserCog2],
["SvgoXCircle", LazySvgoXCircle],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
